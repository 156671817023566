// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("trix")
require("@rails/actiontext")

var Trix  = require("trix")

// change labels for indentation formatting
Trix.config.lang.indent = 'Increase Indent'
Trix.config.lang.outdent = 'Decrease Indent'

// change heading1 from h1 to h3
Trix.config.blockAttributes.heading1 = {
  tagName: 'h3',
  terminal: true,
  breakOnReturn: true,
  group: false,
  nestable: true
}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'bootstrap'
import 'jquery-ui'
